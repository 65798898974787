<template>
  <div class="page flex-row">
    <div class="page-left flex-col justify-end">
      <div class="left-text flex-col">
        <span>大屏广告投放</span>
        <span>直接投放无中介</span>
      </div>
      <img src="@/assets/img/loginPic.png" />
    </div>
    <div class="page-right flex-col justify-center align-center">
      <div class="login-view flex-col justify-center" style="margin-left: 0">
        <span class="login-title">演示账号登录</span>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="ruleForm"
        >
          <el-form-item prop="email">
            <div class="group1">
              <el-input
                type="text"
                placeholder="用户名：demo@daping.cn"
                v-model="ruleForm.email"
              >
              </el-input>
            </div>
          </el-form-item>
          <el-form-item prop="password">
            <div class="group2 flex-row align-center">
              <el-input
                type="text"
                placeholder="密码：123456"
                v-model="ruleForm.password"
              >
              </el-input>
            </div>
          </el-form-item>
        </el-form>
        <div
          :class="
            ruleForm.email && ruleForm.password
              ? 'group3 flex-col'
              : 'disabledGroup3 flex-col'
          "
        >
          <el-button
            type="primary"
            :disabled="!ruleForm.email || !ruleForm.password"
            @click="submitForm"
            class="loginBtn"
            >登录</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable import/no-cycle */
import { login } from "@/api/request";

// const checkEmail = (rule, value, callback) => {
//   if (value != "demo@daping.cn") {
//     callback(new Error("请输入用户名：demo@daping.cn"));
//   } else {
//     callback();
//   }
// };

// const checkPassword = (rule, value, callback) => {
//   if (value != '123456') {
//     callback(new Error("请输入密码：123456"));
//   } else {
//     callback();
//   }
// };

export default {
  components: {},
  data() {
    return {
      ruleForm: {
        email: "demo@daping.cn",
        password: "123456",
      },
      rules: {
        email: [
          {
            required: true,
            message: "请输入用户名：demo@daping.cn",
            trigger: "blur",
          },
          {
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: "用户名格式不正确",
            trigger: "blur",
          },
          // {
          //   validator: checkEmail,
          //   message: "请输入用户名：demo@daping.cn",
          //   trigger: "blur",
          // },
        ],
        password: [
          {
            required: true,
            message: "请输入密码：123456",
            trigger: "blur",
          },
          // {
          //   validator: checkPassword,
          //   message: "请输入密码：123456",
          //   trigger: "blur",
          // },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  created() {},
  methods: {
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          login({
            email: this.ruleForm.email,
            password: this.ruleForm.password,
            style: 0,
          })
            .then(() => {
              this.saveStorage();
              this.$router.replace({ path: "/home" });
            })
            .catch((errCode) => {
              console.log("错误信息", errCode);
            });
        } else {
          return false;
        }
      });
    },
    saveStorage() {
      const { localStorage, sessionStorage } = window;
      sessionStorage.setItem("greenPath", 0);
      localStorage.setItem("isBuyVipNoPrompt", 1);
      localStorage.setItem("noBuyVipNoPrompt", 1);
    },
  },
};
</script>
<style scoped lang="scss">
@import "./index.scss";
.page .page-right .login-view .disabledGroup3 .el-button--primary {
  background: rgba(10, 10, 10, 0.08);
  color: #a4a5a5;
}
::v-deep .el-checkbox__inner::after {
  border-width: 2rem;
  top: 0;
}
</style>
